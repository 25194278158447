var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$tc('configurator.step', 100),"items":_vm.configurationSteps.map(function (item, key) { return (Object.assign({}, item, {key: key})); }),"item-key":"key","headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultConfigurationStepItem},on:{"crudDialogOpen":function (dialogMode, crudItem) { return _vm.stepTypeChanged(crudItem); },"crudDialogSave":function (updatedItems, crudDialogMode, crudItem, crudItemIndex) { return _vm.$emit(
                'crudDialogSave',
                updatedItems,
                crudDialogMode,
                crudItem,
                crudItemIndex
            ); }},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
            var item = ref.item;
return [(Array.isArray(item.data))?_c('span',[_vm._v(_vm._s(item.data.map(function (d) { return d.names[0].name; }).join(", ")))]):_c('span',[_vm._v(_vm._s(item.data.names[0].name))])]}},{key:"crud-form",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{attrs:{"label":_vm.$t('configurator.step.type'),"items":_vm.stepTypes,"rules":[function (v) { return !!v; }],"required":""},on:{"change":function () { return _vm.stepTypeChanged(item); }},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}}),_c('v-select',{attrs:{"label":_vm.$t('configurator.step.data.' + item.type),"items":item.type === 'group' || item.type === 'groups'
                    ? _vm.articleGroups
                    : item.type === 'property' ||
                      item.type === 'globalProperty'
                    ? _vm.properties
                    : [],"item-text":"names[0].name","item-value":function (data) { return data; },"multiple":item.type === 'groups',"rules":[function (v) { return !!v; }],"required":"","value-comparator":_vm.typeValueComparator},model:{value:(item.data),callback:function ($$v) {_vm.$set(item, "data", $$v)},expression:"item.data"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }