
































































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { ConfiguratorConfigurationGroup } from "@/types/configurator";
import { ArticleCategory } from "@/types/shop/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";
import AdminLangCodeNameTable from "@/components/admin/language/name/AdminLangCodeNameTable.vue";
import AdminConfiguratorConfigurationStepTable from "@/components/admin/configurator/configuration/step/AdminConfiguratorConfigurationStepTable.vue";

/**
 * AdminShopArticleCategoryTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
        AdminLangCodeNameTable,
        AdminConfiguratorConfigurationStepTable,
    },
})
export default class AdminShopArticleCategoryTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private configurationGroups: ConfiguratorConfigurationGroup[] = [];
    private articleCategories: ArticleCategory[] = [];

    private defaultConfigurationGroupItem: ConfiguratorConfigurationGroup = {
        id: -1,
        names: [],
        image: {
            id: -1,
            src: "",
            alt: "",
        },
        categories: [],
        steps: [],
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("language.name").toString(),
                value: "names",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            [this.configurationGroups, this.articleCategories] =
                await Promise.all([
                    this.fetchConfigurationGroups(),
                    this.fetchArticleCategories(),
                ]);
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all article categories
     *
     * @returns Promise<ArticleCategory[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchConfigurationGroups(): Promise<
        ConfiguratorConfigurationGroup[]
    > {
        const response = (
            await axios.get<APIResponse<ConfiguratorConfigurationGroup[]>>(
                "/admin/configurator/configuration/group"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all article categories
     *
     * @returns Promise<ArticleCategory[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticleCategories(): Promise<ArticleCategory[]> {
        const response = (
            await axios.get<APIResponse<ArticleCategory[]>>(
                "/articles/categories"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
