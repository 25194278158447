



























































import { Component, Prop, Vue } from "vue-property-decorator";
import {
    ConfiguratorConfigurationStep,
    ConfiguratorConfigurationStepType,
} from "@/types/configurator";
import { Property } from "@/types/property";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { ArticleGroup } from "@/types/shop/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";

/**
 * AdminConfiguratorConfigurationStepTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
    },
})
export default class AdminConfiguratorConfigurationStepTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;
    @Prop({ type: Array, required: true })
    readonly configurationSteps!: ConfiguratorConfigurationStep<
        ArticleGroup | Property
    >[];

    private articleGroups: ArticleGroup[] = [];
    private properties: Property[] = [];

    private defaultConfigurationStepItem: ConfiguratorConfigurationStep<
        ArticleGroup[] | Property
    > = {
        type: ConfiguratorConfigurationStepType.Groups,
        data: [],
        order: -1,
        step: -1,
    };

    /**
     * @returns step types
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get stepTypes(): string[] {
        return Object.values(ConfiguratorConfigurationStepType);
    }

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$t("configurator.step.type").toString(),
                value: "type",
            },
            {
                text: this.$t("configurator.step.data").toString(),
                value: "data",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            [this.articleGroups, this.properties] = await Promise.all([
                this.fetchArticleGroups(),
                this.fetchProperties(),
            ]);
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Compares two properties
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private typeValueComparator(
        a: Property | Property[],
        b: Property | Property[]
    ): boolean {
        if (Array.isArray(a) || Array.isArray(b)) {
            return false;
        }
        return a.id === b.id;
    }

    /**
     * Handler when step type changed.
     * Sets data to empty array or object based on type
     *
     * @param crudItem current crud item
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private stepTypeChanged(
        crudItem: ConfiguratorConfigurationStep<
            Record<string, never> | Property[]
        >
    ) {
        if (
            crudItem.type === ConfiguratorConfigurationStepType.Groups &&
            !Array.isArray(crudItem.data)
        ) {
            crudItem.data = [];
        } else if (
            crudItem.type === ConfiguratorConfigurationStepType.Property &&
            Array.isArray(crudItem.data)
        ) {
            crudItem.data = {};
        }
    }

    /**
     * Fetches all article groups
     *
     * @returns Promise<ArticleGroup[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticleGroups(): Promise<ArticleGroup[]> {
        const response = (
            await axios.get<APIResponse<ArticleGroup[]>>(
                "/admin/articles/groups"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all properties
     *
     * @returns Promise<Property[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchProperties(): Promise<Property[]> {
        const response = (
            await axios.get<APIResponse<Property[]>>("/articles/properties")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
