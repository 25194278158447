






import { Component, Vue } from "vue-property-decorator";
import AdminConfiguratorConfigurationGroupTable from "@/components/admin/configurator/configuration/group/AdminConfiguratorConfigruationGroupTable.vue";

/**
 * AdminConfiguratorConfigruationGroupManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminConfiguratorConfigurationGroupTable,
    },
})
export default class AdminConfiguratorConfigruationGroupManagement extends Vue {}
