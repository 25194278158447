import { Property } from "@/types/property";
import { Image } from "@/types/image";
import { LangCodeName } from "@/types/language";
import {
    ConfiguratorArticle,
    ConfiguratorArticleProperty,
    ConfiguratorArticleGroup,
} from "./article";

import { CartCommissionItem } from "@/store/modules/cart/types";

export enum ConfiguratorConfigurationStepType {
    Function = "function",
    Group = "group",
    Groups = "groups",
    Property = "property",
    GlobalProperty = "globalProperty",
}

export interface ConfiguratorCategory {
    id: number;
    names: LangCodeName[];
    image: Image;
    sales: number;
    orderCount: number;
}

export interface ConfiguratorConfigurationGroup {
    id: number;
    categories: ConfiguratorCategory[];
    names: LangCodeName[];
    image: Image;
    steps: ConfiguratorConfigurationStep<ConfiguratorArticleGroup | Property>[];
}

export interface ConfiguratorConfiguredConfiguration {
    id: number;
    number: string;
    categoryId: number;
    configurationGroupId: number;
    configuration: ConfiguratorConfiguration;
    steps: ConfiguratorConfigurationStep<any>[];
}

export interface ConfiguratorConfigurationDetailsResult {
    id: number;
    configurationGroupId: number;
    number: string;
    description: string;
    categoryId: number;
    configuration: ConfiguratorConfiguration;
}

export interface ConfiguratorValidationResult {
    steps: ConfiguratorConfigurationStep<any>[];
    configuration?: ConfiguratorConfiguration;
}

export interface ConfiguratorConfigurationStep<DataType> {
    type: ConfiguratorConfigurationStepType;
    data: DataType;
    order: number | null;
    step: number;
}

export interface ConfiguratorConfigurationStepResult<
    ItemType = ConfiguratorArticle | ConfiguratorArticleGroup
> {
    type: ConfiguratorConfigurationStepResultType;
    selectedItem: ItemType;
    configuredItem: ItemType;
    quantity: number;
    step: number;
    order: number | null;
}

export enum ConfiguratorConfigurationStepResultType {
    Article = "article",
    Group = "group",
}

export interface ConfiguratorConfiguration {
    stepResults: ConfiguratorConfigurationStepResult[];
    properties: ConfiguratorArticleProperty[];
    additionalItems: CartCommissionItem<ConfiguratorArticle>[];
    image: Image | null;
    price: ConfiguratorConfigurationPrice | null;
    description: string;
}

export interface ConfiguratorConfigurationPrice {
    priceClassId: number;
    price: number;
    currency: string;
    currencyPosition: number;
    tax: number;
}

export interface ConfiguratorConfigurationState<> {
    currentStep: number;
    completedSteps: number;
    selectedCategoryId: number;
    selectedConfigurationGroupId: number;
    categories: ConfiguratorCategory[];
    configurationGroups: ConfiguratorConfigurationGroup[];
    configurationSteps: ConfiguratorConfigurationStep<any>[];
    configuration: ConfiguratorConfiguration;
}

export * from "./article";
export { Property };
